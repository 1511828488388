<template>
  <div>
    <div class="home">
      <div class="home_list">
        <div class="cente" @click="returnFirst()"><img src="../icons/logo_icon@2x.png" alt="">
        </div>
        <div class="icon"><img @click="disk()" src="../icons/caidan_icon@2x.png"></div>
        <van-popup overlay-class="ovew" v-model="show" get-container="body" position="right"
          :style="{ height: '100%' ,width:'50%'}">
          <div class="lists">
            <p></p>
            <p @click="tosecond()">首页</p>
            <p @click="toThird()">关于亘前</p>
            <p @click="toFour()">业务范围</p>
            <p @click="tocontact()">联系我们</p>
          </div>
        </van-popup>
      </div>
    </div>
    <router-view></router-view>

  </div>
</template>
<script>
import router from '@/router';
import { Icon } from 'vant';
import { Popup } from 'vant';
export default {
  data () {
    return {
      show: false,
      disture: !false,
      ture: false,
      scroll: ''
    }
  },
  created () {
    this.show = false
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    returnFirst () {
      this.$router.push('./second')
    },
    // 点击划出
    disk () {
      this.show = !false
    },
    // 监听页面高度
    handleScroll () {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop
      if (this.scroll >= 40) {
        this.disture = false
        this.ture = !false
      } else {
        this.disture = !false
        this.ture = false
      }
    },
    //点击
    tosecond () {
      // console.log('second');
      this.$router.push('./second')
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.show = false
    },
    toThird () {
      this.$router.push('./Third')
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.show = false
    },
    toFour () {
      this.$router.push('./Four')
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.show = false
    },
    tocontact () {
      this.$router.push('./contact')
      document.body.scrollTop = document.documentElement.scrollTop = 0
      this.show = false
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  height: 50px;
  width: 100%;
  position: fixed;
  z-index: 41232;
  // background-color: rgb(0, 0, 0);
  box-shadow: 0px -2px 25px rgba(0, 0, 0, 0.1);
}

.home_list {
  height: 50px;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  z-index: 213;
}
.icon {
  height: 50px;
  width: 70px;
  position: relative;
  display: block;
}
.icon img {
  height: 17px;
  width: 17px;
  position: absolute;
  top: 17px;
  left: 25px;
}
.lists {
  height: 100%;
  width: 100%;
  position: fixed;
  right: 0px;
  top: 0px;
  background-color: black;
}
.ovew {
  height: 100%;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
}
.lists p {
  height: 65px;
  width: 100%;
  font-size: 16px;
  /* margin-top: 10px; */
  text-align: center;
  /* background-color: antiquewhite; */
  line-height: 50px;
  color: white;
}
.lists a {
  height: 65px;
  width: 100%;
  display: block;
  font-size: 16px;
  /* margin-top: 10px; */
  text-align: center;
  /* background-color: antiquewhite; */
  line-height: 50px;
  color: white;
}

.van-popup--left {
  top: 50%;
  left: 0;
  background-color: rgba(141, 136, 136, 0.795);
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.cente {
  height: 50px;
  width: 150px;
  // background-color: rgb(80, 85, 83);
  position: relative;
}
.cente img {
  position: absolute;
  height: 34px;
  width: 108px;
  top: 9px;
  left: 21px;
}
.cente p {
  position: absolute;
  width: 200px;
  top: 21px;
  left: 84px;
  font-size: 24px;
  letter-spacing: 14px;
  font-weight: 500;
  color: white;
}
.cente span {
  width: 200px;
  position: absolute;
  top: 54px;
  letter-spacing: 0px;
  left: 82px;
  // color: #da0303;
  font-size: 12px;
  color: white;
}
//
.foot_lood {
  height: 60px;
  width: 100%;
  background-color: #000000;
}
.fot_top {
  height: 25px;
  width: 100%;
  // background-color: rgb(235, 224, 224);
  text-align: center;
  line-height: 37px;
  font-size: 13px;
  color: rgb(201, 195, 195);
}
.fot_lod {
  height: 25px;
  width: 100%;
  // background-color: rgb(197, 182, 182);
}
.fot {
  height: 25px;
  font-size: 13px;
  width: 240px;
  // background-color: #d2d2d2;
  margin: auto;
  span {
    height: 20px;
    width: 20px;
    display: block;
    margin-top: 5px;
    float: left;
    background-image: url("https://gw.alicdn.com/tfs/TB1jwakrbH1gK0jSZFwXXc7aXXa-20-20.png");
    background-size: 100% 100%;
  }
  p {
    line-height: 32px;
    font-size: 13px;
    height: 35px;
    float: right;
    // background-color: #20aa81;
    width: 220px;
    text-align: left;
    color: rgb(201, 195, 195);
    // padding-left: 15px;
  }
}
.loft {
  height: 204px;
  width: 100%;
  background-color: black;
}
.loft_head {
  height: 40px;
  width: 320px;
  padding-top: 10px;
  // background-color: #999695a6;
  position: relative;
  img {
    position: absolute;
    top: 18px;
    left: 2px;
    height: 30px;
    width: 30px;
  }
  p {
    position: absolute;
    top: 20px;
    left: 41px;
    font-size: 21px;
    letter-spacing: 8px;
    color: #b9b9b9;
  }
}
.loft_main {
  height: 80px;
  width: 320px;
  margin-top: 15px;
  font-size: 13px;
  line-height: 21px;
  color: #b9b9b9;
  // background-color: #d1c2bca6;
}
.tel {
  height: 65px;
  width: 320px;
  margin-top: 7px;
  // background-color: #ffffff;
  p {
    height: 32px;
    width: 320px;
    font-size: 14px;
    line-height: 38px;
    color: #b9b9b9;
    // background-color: #b9b9b9;
  }
  span {
    display: block;
    height: 32px;
    width: 320px;
    font-size: 13px;
    line-height: 22px;
    color: #b9b9b9;
    // background-color: #b9b9b9;
  }
}
.loft_tis {
  height: 200px;
  width: 320px;
  // background-color: #381900;
  margin: auto;
}
</style>