import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		redirect: '/second',
		component: HomeView,
		children: [
			{
				path: '/second',
				name: 'second',
				component: () =>
					import(/* webpackChunkName: "about" */ '../views/second.vue'),
			},
			{
				path: '/Third',
				name: 'Third',
				component: () =>
					import(/* webpackChunkName: "about" */ '../views/Third.vue'),
			},
			{
				path: '/Four',
				name: 'Four',
				component: () =>
					import(/* webpackChunkName: "about" */ '../views/Four.vue'),
			},
			{
				path: '/contact',
				name: 'contact',
				component: () =>
					import(/* webpackChunkName: "about" */ '../views/contact.vue'),
			},
		],
	},
	{
		path: '/determine',
		name: 'determine',
		component: () =>
			import(
				/* webpackChunkName: "about" */ '../views/determine/determine.vue'
			),
	},
]
let originPush = VueRouter.prototype.push //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject) {
	if (resolve && reject) {
		//如果传了回调函数，直接使用
		originPush.call(this, location, resolve, reject)
	} else {
		//如果没有传回调函数，手动添加
		originPush.call(
			this,
			location,
			() => {},
			() => {}
		)
	}
}
// 跳转后返回顶部
// router.afterEach((to, from, next) => {
// 	window.scrollTo(0, 0)
// })
const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
